<template>
	<div :class="classes">
		<figure v-if="imageUrl" :style="style">
			<img :src="imageUrl" :alt="altText" />
		</figure>
	</div>
</template>

<script>
	import ObjectStore from '../../inc/objectStore';

	export default {
		name: 'BlockImage',
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				imageUrl: ''
			};
		},
		computed: {
			altText() {
				return this.block.content.altText || '';
			},
			classes() {
				return `block-inner current-file align-${this.block.meta.align}`;
			},
			style() {
				let style = '';

				if(this.block.meta.maxWidth) {
					style += 'max-width: ' + this.block.meta.maxWidth + ';';
				}

				return style;
			}
		},
		created() {
			if(typeof this.block.meta !== 'object') {
				this.$set(this.block, 'meta', {});
			}

			if(!Object.keys(this.block.meta).length) {
				this.$set(this.block.meta, 'align', 'left');
			}

			if(this.block.content.files && this.block.content.files.length) {
				ObjectStore.getFileUrl(this.block.content.files[0]).then(url => {
					this.imageUrl = url;
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.image.block {
		.block-inner {
			display: flex;
			flex-flow: row wrap;

			&.align-center {
				justify-content: center;
			}

			&.align-right {
				justify-content: flex-end;
			}

			img {
				width: 100%;
				height: auto;
			}
		}
	}
</style>